import { Link } from "gatsby"
import * as React from "react"
import { Container } from "react-bootstrap"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"

const NotFoundPage = () => (
  <div>
    <Header />
    <div className="margin-for-header"></div>
    <section className="error-page">
      <Container>
        <div className="error-content">
          <h1>We're sorry, the page you were looking for cannot be found.</h1>
          <p>
            This may be because it has been removed, we've changed its name or
            it is temporarily unavailable.
          </p>
          <div class="btn-wrapper">
            <Link to="/properties/for-sale/in-dubai/" class="button button-black">
              Find a property
            </Link>
            <Link to="/" class="button button-black">
              Homepage
            </Link>
          </div>
        </div>
      </Container>
    </section>
    <Footer categoryType="category1"/>
  </div>
)

export default NotFoundPage
